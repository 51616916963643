import DropDownMenu from "./DropDownMenu";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import logoDark from "../assets/logo-dark.png";
import logoLight from "../assets/logo-light.png";
import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "./GlobalContext.jsx";
function Navbar() {
  const AuthState = useAuthState();

  //dark mode
  const navigate = useNavigate();
  const currentMode = localStorage.getItem("theme");

  const docElement = document.documentElement;
  if (currentMode === "dark") {
    docElement.classList.add("dark");
  }
  function handleToggleTheme(e) {
    if (e.target.checked) {
      docElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      docElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }
  gsap.registerPlugin(Flip);

  const app = useRef();
  const active = useRef();

  useLayoutEffect(() => {
    const links = document.querySelectorAll(".nav-item");
    const activeNow = document.querySelector(".nav-item a.active");
    //console.log(activeNow);
    const activenav = document.querySelector(".active-nav");
    activeNow?.appendChild(activenav);

    let ctx = gsap.context(() => {
      links.forEach((link) => {
        link.addEventListener("click", () => {
          //change the active, in active and underline colors

          /**
         
          gsap.to(links, { color: "#bdadfc" });

          if (document.activeElement === link) {
            gsap.to(link, { color: "#eaebf5" });
          }
*/

          const state = Flip.getState(activenav);
          link.appendChild(activenav);
          Flip.from(state, {
            duration: 1.25,
            absolute: true,
            ease: "elastic",
          });
        });
      });
    }, app);

    return () => ctx.revert();
  }, []);

  const [scroll, setScroll] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setScroll(true);
      } else setScroll(false);
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const activeLink = ({ isActive }) => ({
    background: isActive
      ? "linear-gradient(94deg, rgba(199, 31, 56,1) 0%, rgba(199, 31, 56,1) 100%)"
      : "",
    backgroundSize: isActive ? "100% 3px" : "",
    backgroundRepeat: isActive ? "no-repeat" : "",
    backgroundPosition: isActive ? "left bottom" : "",
  });

  return (
    <header
      ref={app}
      className={`fixed inset-0 z-50 h-28 w-full  ${
        scroll ? "bg-secondary text-light" : "text-secondary"
      } transitio duration-700 ease-in-out  `}
    >
      {/**
       * //!Fixed mobile nav
       */}
      <div
        className={`fixed-mobile-nav fixed bottom-0 top-28 ${
          toggle ? "right-0" : "right-[-100%]"
        } h-mobile z-[100]  hidden w-full flex-col items-center justify-center bg-red-200 text-secondary  duration-500   dark:bg-dark dark:text-light   amd:flex  `}
      >
        <div className="dark-mode-close-btns w-full flex items-center justify-evenly pt-16">
          {AuthState.userData?.student_token && (
            <div className="hidden sm:block">
              <label className="switch cursor-pointer ">
                <span className="sun">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <g>
                      <circle r="5" cy="12" cx="12"></circle>
                      <path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path>
                    </g>
                  </svg>
                </span>
                <span className="moon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
                  </svg>
                </span>
                <input
                  type="checkbox"
                  className="dark-mode-checkbox"
                  value="dark"
                  onChange={handleToggleTheme}
                  defaultChecked={currentMode === "dark"}
                />
                <span className="slider"></span>
              </label>
            </div>
          )}

          <div className="close-icon  ">
            <FontAwesomeIcon
              onClick={() => setToggle(!toggle)}
              className="hidden   cursor-pointer text-6xl transition-all duration-300 ease-in-out hover:scale-110 active:scale-90 amd:block"
              icon={faXmark}
            />
          </div>
        </div>
        <ul className="nav-links flex h-full   w-full flex-col-reverse items-center  justify-center gap-16 ">
          {!AuthState.userData?.student_token && (
            <>
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/register"> انشاء حساب</Link>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/signin"> تسجيل الدخول</Link>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <a href="#contact">تواصل معنا</a>
                </li>
              </div>
              {AuthState.IsChapters === true && (
                <div className="nav-item relative text-2xl  font-bold">
                  <li
                    className="nav-item text-2xl font-bold"
                    onClick={() => setToggle(!toggle)}
                  >
                    <a href="#courses">الكورسات</a>
                  </li>
                </div>
              )}
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <a href="#academy">الأكاديمية</a>
                </li>
              </div>
              <div className="nav-item relative  ">
                <li
                  className=" text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <a href="#hero">الرئيسية</a>{" "}
                </li>
              </div>
            </>
          )}
          {AuthState.userData?.student_token && (
            <>
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/home/profile">الملف الشخصي</Link>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/question-bank">بنك الاسئلة</Link>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/home/exams">الإختبارات الشهرية</Link>
                </li>
              </div>

              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/home/results">النتائج</Link>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/home/lectures">المحاضرات</Link>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold">
                <li
                  className="nav-item text-2xl font-bold"
                  onClick={() => setToggle(!toggle)}
                >
                  <Link to="/home">الرئيسية</Link>
                </li>
              </div>
            </>
          )}
        </ul>
      </div>
      {/**
       * //!Desktop nav
       */}
      <nav className="width flex h-full items-center justify-between gap-3">
        <FontAwesomeIcon
          className={`burger-icon hidden   cursor-pointer text-5xl transition-all duration-300 ease-in-out hover:scale-110 active:scale-90 amd:block `}
          icon={faBars}
          onClick={() => setToggle(!toggle)}
        />

        <div
          onClick={() => {
            setToggle(false);
            navigate("/home");
          }}
          className="logo cursor-pointer"
        >
          {scroll ? (
            <img className="h-20 w-auto" src={logoDark} alt="logo-dark" />
          ) : (
            <img className="h-20 w-auto" src={logoLight} alt="logo-light" />
          )}
        </div>

        <ul className="nav-links flex h-full w-fit items-center justify-center gap-16 amd:hidden ">
          {AuthState.userData?.student_token && (
            <>
              <div className="nav-item relative text-2xl  font-bold ">
                <li>
                  <NavLink className="underline-gradient " to="/question-bank">
                    بنك الأسئله
                  </NavLink>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold ">
                <li>
                  <NavLink className="underline-gradient " to="/home/exams">
                    الإختبارات الشهرية
                  </NavLink>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold">
                <li>
                  <NavLink className="underline-gradient " to="/home/results">
                    النتائج
                  </NavLink>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold">
                <li>
                  <NavLink className="underline-gradient " to="/home/lectures">
                    المحاضرات
                  </NavLink>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold">
                <li>
                  <NavLink className="underline-gradient " to="/home/">
                    الرئيسية
                  </NavLink>
                </li>
                <div
                  className={`active-nav underline-nav absolute bottom-[-4px] left-0 h-1 w-full bg-light-gray ${
                    scroll ? "bg-light amd:bg-secondary" : "bg-secondary"
                  } `}
                ></div>
              </div>
            </>
          )}
          {!AuthState.userData?.student_token && (
            <>
              <div className="nav-item relative text-2xl  font-bold ">
                <li>
                  <a href="#contact">تواصل معنا</a>
                </li>
              </div>
              {AuthState.IsChapters === true && (
                <div className="nav-item relative text-2xl  font-bold">
                  <li>
                    <a href="#courses">الكورسات</a>
                  </li>
                </div>
              )}
              <div className="nav-item relative text-2xl  font-bold">
                <li>
                  <a href="#academy">الأكاديمية</a>
                </li>
              </div>
              <div className="nav-item relative text-2xl  font-bold">
                <li>
                  <a href="#hero">الرئيسية</a>
                </li>
                {/***/}
                <div
                  className={`active-nav underline-nav absolute bottom-[-4px] left-0 h-1 w-full bg-light-gray ${
                    scroll ? "bg-light amd:bg-secondary" : "bg-secondary"
                  } `}
                ></div>
              </div>
            </>
          )}
        </ul>

        <div className="nav__buttons-dark-btns flex items-center justify-end  gap-10 ">
          {AuthState.userData?.student_token ? (
            <>
              <div className="sm:hidden">
                <label className="switch cursor-pointer ">
                  <span className="sun">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <g>
                        <circle r="5" cy="12" cx="12"></circle>
                        <path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path>
                      </g>
                    </svg>
                  </span>
                  <span className="moon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
                    </svg>
                  </span>
                  <input
                    type="checkbox"
                    className="dark-mode-checkbox"
                    value="dark"
                    onChange={handleToggleTheme}
                    defaultChecked={currentMode === "dark"}
                  />
                  <span className="slider"></span>
                </label>
              </div>
              <DropDownMenu scroll={scroll} />
            </>
          ) : (
            <>
              <label className="switch cursor-pointer">
                <span className="sun">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <g>
                      <circle r="5" cy="12" cx="12"></circle>
                      <path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path>
                    </g>
                  </svg>
                </span>
                <span className="moon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
                  </svg>
                </span>
                <input
                  type="checkbox"
                  className="dark-mode-checkbox"
                  value="dark"
                  onChange={handleToggleTheme}
                  defaultChecked={currentMode === "dark"}
                />
                <span className="slider"></span>
              </label>

              <button
                onClick={() => navigate("/signin")}
                className="nav__buttons-btn1  "
                type="button"
              >
                <strong>تسجيل الدخول</strong>
                <div id="container-stars">
                  <div id="stars"></div>
                </div>

                <div id="glow">
                  <div className="btn1-circle"></div>
                  <div className="btn1-circle"></div>
                </div>
              </button>
            </>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
