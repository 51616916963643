import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditVideo({ edit }) {
  const [values, setValues] = useState(null);
  const { VideoID, lectureID } = useParams();
  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditVideo": {
        return {
          ...state,
          submitAddEditVideo: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditVideo: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_VIDEO_API
    : process.env.REACT_APP_ADMIN_ADD_VIDEO_API;

  //!--------- add edit Video -------

  const [VideoAddEditSuccess, VideoAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditVideo.flag,
    state.submitAddEditVideo.dependency,
    state.submitAddEditVideo.data,
    true
  );

  //!--------- get the Video info for editing -------

  const [VideoInfo, VideoInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_VIDEO_INFO_API}${VideoID}`,
    "GET",
    VideoID,
    VideoID
  );
  useEffect(() => {
    if (VideoInfo) {
      setValues({
        ...VideoInfo.data,
        is_public: VideoInfo.data.is_public === 1 ? true : false,
      });
    }
  }, [VideoInfo]);

  useEffect(() => {
    //!---add actions ----

    if (VideoAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [VideoAddEditSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  const onSubmit = (data) => {
    const finalData = lectureID
      ? {
          ...data,
          lecture_id: lectureID,
          is_public: data?.is_public === true ? 1 : 0,
        }
      : VideoID
      ? {
          ...data,
          id: VideoID,
          is_public: data?.is_public === true ? 1 : 0,
        }
      : "";
    dispatch({
      type: "setSubmitAddEditVideo",
      payload: {
        flag: "AddEditVideo",
        dependency: !state.submitAddEditVideo.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="الفيديوهات | الفيزيائي معتز رضوان"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {VideoID
            ? "تعديل بيانات الفيديو"
            : "من فضلك قم بملئ البيانات لإضافة الفيديو"}
        </h2>

        <form
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-red-200 p-16 shadow-lg shadow-red-200/50"
        >
          {/** Name platform filed */}
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div
              className={`flex w-1/2 flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
            >
              <label className="w-full truncate" htmlFor="platform">
                المنصة{" "}
              </label>
              <select
                name="platform"
                id="platform"
                {...register("platform", { required: true })}
              >
                <option value="youtube">youtube</option>
                <option value="vimeo">vimeo</option>
                <option value="vdocipher">vdocipher</option>
              </select>

              {errors.platform && (
                <p className="text-[12px] text-red-900 ">
                  {errors.platform.type === "required" && "برجاء ملئ هذا الحقل"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                VideoAddEditErrors &&
                  VideoAddEditErrors?.response?.data?.errors?.platform && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {VideoAddEditErrors?.response?.data?.errors?.platform[0]}
                    </p>
                  )
              }
            </div>
            <div className="name flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">الإسم</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="name"
                placeholder="الإسم"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {errors.name.type === "required" && "برجاء ملئ هذا الحقل"}
                  {errors.name.type === "pattern" &&
                    "يسمح فقط باستخدام الحروف الإنجليزية"}
                  {errors.name.type === "maxLength" &&
                    "أقصي عدد للحروف هو ٤٠ حرف"}
                  {errors.name.type === "minLength" &&
                    "من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                </p>
              )}

              {
                //!-------server errors -----

                VideoAddEditErrors &&
                  VideoAddEditErrors?.response?.data?.errors?.name && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {VideoAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/** vdo_id is_public*/}
          <div className="flex  w-full items-center justify-between gap-16 md:flex-col md:gap-10 ">
            <div className=" vdo_id flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="vdo_id">
                رابط الفيديو{" "}
              </label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="vdo_id"
                placeholder="رابط الفيديو"
                name="vdo_id"
                autoComplete="on"
                {...register("vdo_id", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 155,
                  minLength: 3,
                })}
              />
              {errors.vdo_id && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {errors.vdo_id.type === "required" && "برجاء ملئ هذا الحقل"}
                  {errors.vdo_id.type === "pattern" &&
                    "يسمح فقط باستخدام الحروف الإنجليزية"}
                  {errors.vdo_id.type === "maxLength" &&
                    "أقصي عدد للحروف هو ١٥٥ حرف"}
                  {errors.vdo_id.type === "minLength" &&
                    "من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                </p>
              )}
              {
                //!-------server errors -----

                VideoAddEditErrors &&
                  VideoAddEditErrors?.response?.data?.errors?.vdo_id && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {VideoAddEditErrors?.response?.data?.errors?.vdo_id[0]}
                    </p>
                  )
              }
            </div>
            <div className=" is_public flex w-1/2 flex-col items-end justify-center gap-2 md:w-full ">
              <div className="flex w-full items-center justify-end gap-10">
                <label className="visibility-switch">
                  <input
                    className=""
                    id="is_public"
                    name="is_public"
                    {...register("is_public", {
                      required: false,
                    })}
                    type="checkbox"
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div>
                  <label
                    className="w-full cursor-pointer truncate"
                    htmlFor="is_public"
                  >
                    مجاني{" "}
                  </label>
                </div>
              </div>

              {errors.is_public && (
                <p className="text-[12px] text-red-900 ">
                  {errors.is_public.type === "required" &&
                    "برجاء ملئ هذا الحقل"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                VideoAddEditErrors &&
                  VideoAddEditErrors?.response?.data?.errors?.is_public && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {VideoAddEditErrors?.response?.data?.errors?.is_public[0]}
                    </p>
                  )
              }
            </div>
          </div>

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              الوصف{" "}
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className="text-[12px] text-red-900 ">
                {errors.description.type === "maxLength" &&
                  "أقصي عدد للحروف هو ١٥٥ حرف"}
                {errors.description.type === "minLength" &&
                  " من فضلك فم بكتابة ٣ أحرف علي الاقل"}
              </p>
            )}
            {
              //!-------server errors -----

              VideoAddEditErrors &&
                VideoAddEditErrors?.response?.data?.errors?.description && (
                  <p className="w-full text-end text-[12px] text-red-900  ">
                    {VideoAddEditErrors?.response?.data?.errors?.description[0]}
                  </p>
                )
            }
          </div>
          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditVideo.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditVideo.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p> {VideoID ? "تأكيد تعديل بيانات الفيديو" : "اضافة الفيديو"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditVideo;
