import React, { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Col, Popconfirm, Row, message } from "antd";
import { AdminFilesAddFile } from "./AdminFilesAddFile";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { AdminViewAllFiles } from "./AdminViewAllFiles";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";

const AdminFiles = () => {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);

  const [allFilesData, allFilesErrors, allFilesloading] = useAxios(
    process.env.REACT_APP_ADMIN_FILES_API,
    "GET",
    "GET",
    reFetch
  );

  if (allFilesloading) return <Loader />;

  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="الملفات | الفيزيائي معتز رضوان"></HelmetTags>{" "}
      <h2 className="w-full text-end text-4xl font-bold md:text-center">
        الملفات
      </h2>
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions.some((permission) =>
          permission.name.startsWith("file_upload")
        )) && <AdminFilesAddFile setReFetch={setReFetch} />}
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions.some((permission) =>
          permission.name.startsWith("file_view")
        )) && (
        <AdminViewAllFiles
          allFilesErrors={allFilesErrors}
          allFilesData={allFilesData}
          setReFetch={setReFetch}
        />
      )}
    </section>
  );
};

export default AdminFiles;
